<template>
  <div></div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
export default {
  data() {
    return {
      gid: "",
      aid: "",
      uid: "",
    };
  },
  methods: {},
  created() {
    this.uid = utils.getUrlKey("uid");
    this.aid = utils.getUrlKey("aid");
    this.gid = utils.getUrlKey("gid");
    // sessionStorage.setItem("uid", this.uid);
    // sessionStorage.setItem("aid", this.aid);
    // sessionStorage.setItem("gid", this.gid);
    localStorage.setItem("isok", true);
    if (this.$route.query.code) {
      let data = {
        code: this.$route.query.code,
        gid: 484,
      };
      //如果连接中能拿到code说明此时access_token没有或者已过期，需要重新获取token
      // const token = localStorage.getItem("hdtoken");
      // if (!token) {
      userModel.auth(data).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("hdtoken", res.data.authorization);
          // localStorage.setItem("carNo", res.data.carNo);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          let url = sessionStorage.getItem("tochengong");
          // this.$router.push(url);
          console.log(sessionStorage.getItem("tobaoming"));
          if (sessionStorage.getItem("lujin") == "activity") {
            this.$router.push({
              path: 'activity',
            });
            return;
          } else if (sessionStorage.getItem("lujin") == "newindex") {
            this.$router.push({
              path: '/newindex',
            });
            return;
          } else if (sessionStorage.getItem("lujin") == "manage") {
            this.$router.push({
              path: '/manage',
            });
            return;
          } else if (sessionStorage.getItem("lujin") == "activity_b") {
            this.$router.push({
              path: '/activity_b',
            });
            return;
          }
          if (sessionStorage.getItem("tobaoming") == "true") {
            // console.log('走这1')
            this.$router.push({
              path: "apply",
            });
          } else {
            let url = localStorage.getItem("beforeUrl");
            //  console.log('走这2')
            this.$router.push({
              path: url,
            });
          }

          // window.close()
        } else {
          this.Toast(res.data.msg);
        }
      });
      // } else {
      // }
    }
  },
  // mounted() {
  //   let that = this;
  //   const token = sessionStorage.getItem("Authorization");
  //   if (token) {
  //     userModel
  //       .config({
  //         url: location.href.split("#")[0],
  //         gid: 208
  //       })
  //       .then(res => {
  //         if (res.code == 200) {
  //           console.log(res);
  //           wx.config({
  //             debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //             appId: "wx406b62706ded5aa8", // 必填，公众号的唯一标识
  //             timestamp: res.data.timestamp, // 必填，生成签名的时间戳
  //             nonceStr: res.data.noncestr, // 必填，生成签名的随机串
  //             signature: res.data.signature, // 必填，签名
  //             jsApiList: [
  //               "openLocation",
  //               "updateAppMessageShareData",
  //               "updateTimelineShareData",
  //               "onMenuShareAppMessage",
  //               "onMenuShareTimeline"
  //             ] // 必填，需要使用的JS接口列表
  //           });
  //         } else {
  //           this.$toast(res.data.msg);
  //         }
  //       });
  //     this.cheshi();
  //   }
  // }
};
</script>
